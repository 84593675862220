import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.css']
})
export class DateTimePickerComponent {
    value: FormControl;
    @Input() enableMeridian: boolean
    @Input() color: string;
    @Input() label: string;
    @ViewChild('dynamicRef') dateTimePicker: MatDatepicker<any>;
    @Input() disabled;
    @Input() minDate;
    @Input() maxDate;

    @Input() set control(val: any) {
        this.value = val;
    };

    get control() {
        return new Date(this.value.value);
    }
}
