<form [formGroup]="form" appFormValidator>
    <div class="d-flex align-items-baseline flex-wrap">
        <div class="col-md-6 align-items-baseline flex-wrap">
            <div class="form-row mt-4">
                <div class="col-12">
                    <app-gradient-border-title>{{ "InventoryApprovalReport" | translate }}</app-gradient-border-title>
                </div>
                <div class="col-md-6">
                    <app-date-picker-form-field [id]="'approval_report_date'" [control]="form.controls['approval_report_date']" [label]="'InventoryApprovalReportDate'"></app-date-picker-form-field>
                </div>
                <div class="col-md-6">
                    <app-input-form-field [id]="'approval_report_number'" [control]="form.controls['approval_report_number']" [label]="'InventoryApprovalReportNo'"></app-input-form-field>
                </div>
                <div class="col-md-6">
                    <app-input-form-field [id]="'approval_report_author_name'" [control]="form.controls['approval_report_author_name']" [label]="'InventoryApprovalReportAuthorName'"></app-input-form-field>
                </div>
                <div class="col-md-6">
                    <app-input-form-field [id]="'approval_report_author_position'" [control]="form.controls['approval_report_author_position']" [label]="'InventoryApprovalReportAuthorPosition'"></app-input-form-field>
                </div>
            </div>
            <div class="form-row mt-4">
                <div class="col-12">
                    <app-gradient-border-title>{{ "InventoryApprovalDecision" | translate }}</app-gradient-border-title>
                </div>
                <div class="col-md-6">
                    <app-input-form-field [id]="'approval_decision_number'" [control]="form.controls['approval_decision_number']" [label]="'InventoryApprovalDecisionNo'"></app-input-form-field>
                </div>
                <div class="col-md-6">
                    <app-date-picker-form-field [id]="'approval_decision_date'" [control]="form.controls['approval_decision_date']" [label]="'InventoryApprovalDecisionDate'"></app-date-picker-form-field>
                </div>
                
            </div>
            <div class="form-row mt-4">
                <div class="col-12">
                    <app-gradient-border-title>{{ "InventoryTraining" | translate }}</app-gradient-border-title>
                </div>
                <div class="col-md-6">
                    <app-input-form-field [id]="'trainer_name'" [control]="form.controls['trainer_name']" [label]="'InventoryTrainerName'"></app-input-form-field>
                </div>
                <div class="col-md-6">
                    <app-input-form-field [id]="'trainer_position'" [control]="form.controls['trainer_position']" [label]="'InventoryTrainerPosition'"></app-input-form-field>
                </div>
                <div class="col-md-6">
                    <app-date-time-picker [id]="'training_date_and_hour'" [control]="form.controls['training_date_and_hour']" [label]="'InventoryTrainingDateAndHour'"></app-date-time-picker>
                </div>
                <div class="col-md-6">
                    <app-input-form-field [id]="'training_location'" [control]="form.controls['training_location']" [label]="'InventoryTrainingLocation'"></app-input-form-field>
                </div>
                <div class="col-md-4">
                    <app-date-picker-form-field [id]="'training_notification_date'" [control]="form.controls['training_notification_date']" [label]="'InventoryTrainingNotificationDate'"></app-date-picker-form-field>
                </div>
                <div class="col-md-4">
                    <app-date-picker-form-field [id]="'training_report_date'" [control]="form.controls['training_report_date']" [label]="'InventoryTrainingReportDate'"></app-date-picker-form-field>
                </div>
                <div class="col-md-4">
                    <app-input-form-field [id]="'training_procedure_code'" [control]="form.controls['training_procedure_code']" [label]="'InventoryTrainingProcedureCode'"></app-input-form-field>
                </div>
                <div class="col-md-12">
                    <app-text-area-form-field [id]="'training_provided_protective_equipment'" [control]="form.controls['training_provided_protective_equipment']" [label]="'InventoryTrainingProvidedProtectiveEquipment'" maxRows="3"></app-text-area-form-field>
                </div>
                <div class="col-md-12">
                    <app-text-area-form-field [id]="'training_provided_tools'" [control]="form.controls['training_provided_tools']" [label]="'InventoryTrainingProvidedTools'" maxRows="3"></app-text-area-form-field>
                </div>
            </div>
        </div>
        <div class="form-row mt-4 col-md-6">
            <div class="col-12">
                <app-gradient-border-title>{{ "InventoryDeclaration" | translate }}</app-gradient-border-title>
            </div>
            <div class="col-md-6">
                <app-input-form-field [id]="'declaration_number'" [control]="form.controls['declaration_number']" [label]="'InventoryDeclarationNumber'"></app-input-form-field>
            </div>
            <div class="col-md-6">
                <app-date-picker-form-field [id]="'declaration_date'" [control]="form.controls['declaration_date']" [label]="'InventoryDeclarationDate'"></app-date-picker-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field  [id]="'declaration_storage_place'" [control]="form.controls['declaration_storage_place']" [label]="'InventoryDeclarationStoragePlace'" maxRows="3"></app-text-area-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field [id]="'declaration_third_parties_values'" [control]="form.controls['declaration_third_parties_values']" [label]="'InventoryDeclarationThirdPartiesValues'" maxRows="3"></app-text-area-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field [id]="'declaration_acknowledged_stock_differences'" [control]="form.controls['declaration_acknowledged_stock_differences']" [label]="'InventoryDeclarationAcknowledgedStockDifferences'" maxRows="3"></app-text-area-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field [id]="'declaration_acknowledged_undocumented_stock_items'" [control]="form.controls['declaration_acknowledged_undocumented_stock_items']" [label]="'InventoryDeclarationAcknowladgedUnocumentedStockItems'" maxRows="3"></app-text-area-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field [id]="'declaration_documented_stock_items'" [control]="form.controls['declaration_documented_stock_items']" [label]="'InventoryDeclarationDocumentedStockItems'" maxRows="3"></app-text-area-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field [id]="'declaration_unprocessed_documents_for_the_stock_items'" [control]="form.controls['declaration_unprocessed_documents_for_the_stock_items']" [label]="'InventoryDeclarationUnprocessedDocumentsForTheStockItems'" maxRows="3"></app-text-area-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field  [id]="'declaration_cash_value'" [control]="form.controls['declaration_cash_value']" [label]="'InventoryDeclarationCashValue'" maxRows="3"></app-text-area-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field [id]="'declaration_stock_entry_documents'" [control]="form.controls['declaration_stock_entry_documents']" [label]="'InventoryDeclarationStockEntryDocuments'" maxRows="3"></app-text-area-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field [id]="'declaration_stock_exit_documents'" [control]="form.controls['declaration_stock_exit_documents']" [label]="'InventoryDeclarationStockExitDocuments'" maxRows="3"></app-text-area-form-field>
            </div>
            <div class="col-md-4">
                <app-date-picker-form-field [id]="'declaration_last_report_date'" [control]="form.controls['declaration_last_report_date']" [label]="'InventoryDeclarationLastReportDate'"></app-date-picker-form-field>
            </div>
            <div class="col-md-4">
                <app-date-picker-form-field [id]="'declaration_last_report_period_start_date'" [control]="form.controls['declaration_last_report_period_start_date']" [label]="'InventoryDeclarationLastReportPeriodStartDate'"></app-date-picker-form-field>
            </div>
            <div class="col-md-4">
                <app-date-picker-form-field [id]="'declaration_last_report_period_end_date'" [control]="form.controls['declaration_last_report_period_end_date']" [label]="'InventoryDeclarationLastReportPeriodEndDate'"></app-date-picker-form-field>
            </div>
            <div class="col-md-12">
                <app-text-area-form-field [id]="'declaration_other_information'" [control]="form.controls['declaration_other_information']" [label]="'InventoryDeclarationOtherInformation'" maxRows="3"></app-text-area-form-field>
            </div>
        </div>
    </div>
</form>
