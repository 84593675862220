<mat-form-field #matFormField class="price-field ml-2 customized" [class.disabled-input]="disabledInput" floatLabel="always">
    <div class="placeholder-focus" #placeholderFocus></div>
    <div class="d-flex">
        <span class="material-icons price-icon" *ngIf="focusOnInput">loop</span>
        <span class="material-icons price-icon" *ngIf="savePrice">check</span>
        <input matInput #priceInput type="text" (focus)="onFocus()" (focusout)="onFocusOut()"
            (keydown)="enterSubmit($event)" name="price" id="price-{{index}}" [formControl]="form.controls.price">
    </div>
    <mat-error *ngFor="let validation of validationMessages.float">
        <small>
            <mat-error class="error-message"
                *ngIf="form.get('price').hasError(validation.type) && (form.get('price').dirty || form.get('price').touched)">
                {{validation.message | translate}}
            </mat-error>
        </small>
    </mat-error>
</mat-form-field>