import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
    selector: 'app-date-picker-form-field',
    templateUrl: './date-picker-form-field.component.html',
    styleUrls: ['./date-picker-form-field.component.css']
})
export class DatePickerFormFieldComponent {
    @Input() control: FormControl;
    @Input() label: string;
    @Input() id: string;
    @ViewChild('dynamicRef') datepicker: MatDatepicker<any>;

    dateChanged(e) {
        this.control.setValue(moment(e.value).format('YYYY-MM-DD'))
    }
}
