<mat-form-field class="customized" floatLabel="always">
    <mat-select #select matSelect [id]="cypressSelector" (selectionChange)="onSelectionChange($event.value)" disableOptionCentering [formControl]="control" multiple>
        <mat-option class="search-option">
            <ngx-mat-select-search
                [formControl]="filterFormControl"
                [placeholderLabel]="'QuickSearch' | translate"
                [noEntriesFoundLabel]="'NoResults' | translate">
            </ngx-mat-select-search>
        </mat-option>
        <div class="select-all">
            <mat-checkbox [(ngModel)]="allSelected"
                [ngModelOptions]="{ standalone: true }"
                (change)="toggleAllSelection()"
                [disabled]="!itemsList?.length">
                {{ "SelectAll" | translate }}
            </mat-checkbox>
        </div>
        <mat-option *ngFor="let item of filteredItemsList" [value]="item.id || item.user_id" [id]="item.id || item.user_id" (click)="toggleSingleSelection()">{{
            customField && item[customField] ||
            item?.name && item.name ||
            item?.title && item.title ||
            item?.series && item.series ||
            item?.first_name && (item.last_name + ' ' + item.first_name) 
            }}
        </mat-option>
    </mat-select>
</mat-form-field>