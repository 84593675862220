import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InventoryModel } from '../../../models/inventory.model';

@Component({
    selector: 'app-inventory-form-fields',
    templateUrl: './inventory-form-fields.component.html',
    styleUrls: ['./inventory-form-fields.component.css']
})
export class InventoryFormFieldsComponent implements OnInit {
    public form: FormGroup;
    public selectedInventory: InventoryModel;
    @Input() isDisabled: boolean;
    @Input() set data(val: any) {
        this.selectedInventory = val;
        this.form && val && this.form.patchValue({ ...val });
    };

    get data() {
        return this.selectedInventory;
    }
    @Output() updateManagerForm: EventEmitter<FormGroup> = new EventEmitter();

    constructor(private _formBuilder: FormBuilder) { }
    ngOnInit(): void {
        this.initForm()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isDisabled'].currentValue === true) {
            this.initForm()
        }
    }
    initForm() {
        this.form = this._formBuilder.group({
            manager_name: { value: this.data?.manager_name || '', disabled: this.isDisabled },
            manager_position: { value: this.data?.manager_position || '', disabled: this.isDisabled },
            company_manager_name: { value: this.data?.company_manager_name || '', disabled: this.isDisabled },
            company_manager_position: { value: this.data?.company_manager_position || '', disabled: this.isDisabled },
            company_manager_investment_decision_number: { value: this.data?.company_manager_investment_decision_number || '', disabled: this.isDisabled },
            company_manager_investment_decision_date: { value: this.data?.company_manager_investment_decision_date || '', disabled: this.isDisabled },

            lists_author_name: { value: this.data?.lists_author_name || '', disabled: this.isDisabled },
            lists_author_position: { value: this.data?.lists_author_position || '', disabled: this.isDisabled },
            claims_and_obligations_approval_responsible_name: { value: this.data?.claims_and_obligations_approval_responsible_name || '', disabled: this.isDisabled },
            claims_and_obligations_approval_responsible_position: { value: this.data?.claims_and_obligations_approval_responsible_position || '', disabled: this.isDisabled },
        });
        this.updateManagerForm.emit(this.form);
        this.form.valueChanges.subscribe(() => this.updateManagerForm.emit(this.form));
    }
}
