<div class="row dialog-top-header d-flex" mat-dialog-title>
        <div class="col-10 dialog-pre-title">
            <small>{{"StockManagement" | translate}}</small>
        </div>
        <div class="col-2 text-right">
            <button class="btn btn-secondary-custom small-btn"
                    (click)="dialogRef.close()">
                <span class="material-icons">close</span>
            </button>
        </div>
        <div class="col-12">
            <h1 class="mb-0 dialog-title"
                *ngIf="isEdit">
                {{'EditStockManagement' | translate}}:
                <span *ngIf="selectedStockManagement$ | async as stockManagement; else namePlaceholder" data-cy="stock-management-name-title">{{stockManagement?.name}}</span>
                <ng-template #namePlaceholder>-</ng-template>
            </h1>
    
            <h1 class="mb-0 dialog-title"
                *ngIf="!isEdit">
                {{'AddStockManagement' | translate}}
            </h1>
        </div>
    

</div>
<mat-dialog-content>
    <div class="position-relative">
        <div class="loading-overlay w-100"
             *ngIf="(loading$ | async)">
            <span class="spinner"></span>
        </div>
        <form [formGroup]="form" appFormValidator>
            <div class="form-row mt-4">
                <div class="col-12 col-md-4">
                    <mat-form-field class="w-100" class="customized" floatLabel="always">
                        <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                        <input type="text"
                            matInput
                            id="name"
                            autocomplete="off"
                            data-cy="stock-management-name"
                            formControlName="name">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['name']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <mat-form-field class="w-100" class="customized" floatLabel="always">
                        <mat-label for="code">{{ 'Code' | translate }}:</mat-label>
                        <input type="text"
                            matInput
                            id="code"
                            data-cy="stock-management-code"
                            autocomplete="off"
                            formControlName="code">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['code']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<div class="mb-3">
    <mat-dialog-actions>
        <app-dialog-footer-buttons class="w-100"
                           (close)="dialogRef.close()"
                           (saveAndClose)="saveAndClose()"
                           (save)="onSubmit()">
        </app-dialog-footer-buttons>
    </mat-dialog-actions>
</div>