<mat-form-field class="customized" floatLabel="always">
    <mat-label [attr.for]="dynamicRef">{{ label | translate }}</mat-label>
    <input matInput [matDatepicker]="dynamicRef" [formControl]="control"
        (click)="dynamicRef.open()" (dateChange)="dateChanged($event)">
    <mat-datepicker-toggle matSuffix [for]="dynamicRef" class="date-picker-btn"></mat-datepicker-toggle>
    <mat-datepicker #dynamicRef></mat-datepicker>
    <mat-error>
        <app-form-field-error [control]="control"></app-form-field-error>
    </mat-error>
</mat-form-field>
