<ng-container *ngIf="filterControls">
    <mat-form-field class="customized" [attr.data-cy]="data_cy" floatLabel="always">
        <mat-label>{{ label | translate }}</mat-label>
        <div class="d-flex align-items-center">
            <mat-date-range-input [formGroup]="form" [rangePicker]="picker" [updateDateRangeValueDirective]>
                <input matInput matStartDate formControlName="{{filterControls[0]}}" (click)="picker.open()" [attr.data-cy]="data_cy + '-start-date'">
                <input matInput matEndDate formControlName="{{filterControls[1]}}" (click)="picker.open()" [attr.data-cy]="data_cy + '-end-date'">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
        <mat-error>
            <app-form-field-error [control]="form.controls[filterControls[0]]"></app-form-field-error>
            <app-form-field-error [control]="form.controls[filterControls[1]]"></app-form-field-error>
        </mat-error>
    </mat-form-field>
</ng-container>