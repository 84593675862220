    <mat-form-field class="w-100" class="customized" floatLabel="always">
        <mat-label [attr.for]="id">{{ label | translate }}:</mat-label>
        <input [type]="type? type :'text'"
            matInput
            [id]="id"
            autocomplete="off"
            [formControl]="control">
        <mat-error>
            <app-form-field-error [control]="control"></app-form-field-error>
        </mat-error>
    </mat-form-field>