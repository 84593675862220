import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InventoryModel } from '../../../models/inventory.model';

@Component({
    selector: 'app-docs-tab',
    templateUrl: './docs-tab.component.html',
    styleUrls: ['./docs-tab.component.css']
})
export class DocsTabComponent {
    public form: FormGroup;
    public selectedInventory: InventoryModel;
    @Input() isDisabled: boolean;
    @Input() set data(val: any) {
        this.selectedInventory = val;
        this.form && val && this.form.patchValue({ ...val, training_date_and_hour: this.data?.training_date_and_hour ? new Date(this.data?.training_date_and_hour) : '' },);
    };

    get data() {
        return this.selectedInventory;
    }
    @Output() updateDocsForm: EventEmitter<FormGroup> = new EventEmitter();

    constructor(private _formBuilder: FormBuilder) { }
    ngOnInit(): void {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isDisabled'].currentValue === true) {
            this.initForm()
        }
    }

    initForm() {
        this.form = this._formBuilder.group({
            approval_report_number: { value: this.data?.approval_report_number || '', disabled: this.isDisabled },
            approval_report_date: { value: this.data?.approval_report_date || '', disabled: this.isDisabled },
            approval_report_author_name: { value: this.data?.approval_report_author_name || '', disabled: this.isDisabled },
            approval_report_author_position: { value: this.data?.approval_report_author_position || '', disabled: this.isDisabled },
            approval_decision_number: { value: this.data?.approval_decision_number || '', disabled: this.isDisabled },
            approval_decision_date: { value: this.data?.approval_decision_date || '', disabled: this.isDisabled },
            trainer_name: { value: this.data?.trainer_name || '', disabled: this.isDisabled },
            trainer_position: { value: this.data?.trainer_position || '', disabled: this.isDisabled },
            training_date_and_hour: { value: this.data?.training_date_and_hour || null, disabled: this.isDisabled },
            training_location: { value: this.data?.training_location || '', disabled: this.isDisabled },
            training_notification_date: { value: this.data?.training_notification_date || '', disabled: this.isDisabled },
            training_report_date: { value: this.data?.training_report_date || '', disabled: this.isDisabled },
            training_procedure_code: { value: this.data?.training_procedure_code || '', disabled: this.isDisabled },
            training_provided_protective_equipment: { value: this.data?.training_provided_protective_equipment || '', disabled: this.isDisabled },
            training_provided_tools: { value: this.data?.training_provided_tools || '', disabled: this.isDisabled },
            declaration_number: { value: this.data?.declaration_number || '', disabled: this.isDisabled },
            declaration_date: { value: this.data?.declaration_date || '', disabled: this.isDisabled },
            declaration_storage_place: { value: this.data?.declaration_storage_place || '', disabled: this.isDisabled },
            declaration_third_parties_values: { value: this.data?.declaration_third_parties_values || '', disabled: this.isDisabled },
            declaration_acknowledged_stock_differences: { value: this.data?.declaration_acknowledged_stock_differences || '', disabled: this.isDisabled },
            declaration_acknowledged_undocumented_stock_items: { value: this.data?.declaration_acknowledged_undocumented_stock_items || '', disabled: this.isDisabled },
            declaration_documented_stock_items: { value: this.data?.declaration_documented_stock_items || '', disabled: this.isDisabled },
            declaration_unprocessed_documents_for_the_stock_items: { value: this.data?.declaration_unprocessed_documents_for_the_stock_items || '', disabled: this.isDisabled },
            declaration_cash_value: { value: this.data?.declaration_cash_value || '', disabled: this.isDisabled },
            declaration_stock_entry_documents: { value: this.data?.declaration_stock_entry_documents || '', disabled: this.isDisabled },
            declaration_stock_exit_documents: { value: this.data?.declaration_stock_exit_documents || '', disabled: this.isDisabled },
            declaration_last_report_date: { value: this.data?.declaration_last_report_date || '', disabled: this.isDisabled },
            declaration_last_report_period_start_date: { value: this.data?.declaration_last_report_period_start_date || '', disabled: this.isDisabled },
            declaration_last_report_period_end_date: { value: this.data?.declaration_last_report_period_end_date || '', disabled: this.isDisabled },
            declaration_other_information: { value: this.data?.declaration_other_information || '', disabled: this.isDisabled },
        });
        this.updateDocsForm.emit(this.form);
        this.form.valueChanges.subscribe(() => this.updateDocsForm.emit(this.form));
    }
}
