import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-text-area-form-field',
    templateUrl: './text-area-form-field.component.html',
    styleUrls: ['./text-area-form-field.component.css']
})
export class TextAreaFormFieldComponent {
    @Input() control: FormControl;
    @Input() label: string;
    @Input() maxRows: number;
    @Input() id: string;
}
