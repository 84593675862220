import { NgModule } from '@angular/core';
import { DropdownMenuOptionsComponent } from './dropdown-menu-options/dropdown-menu-options.component';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../directives/directives.module';

import { MaterialModule } from '../modules/material.module';
import { BadgeComponent } from './badge/badge.component';
import { DateIntervalPickerComponent } from './date-interval-picker/date-interval-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DialogFooterButtonsComponent } from './dialog-footer-buttons/dialog-footer-buttons.component';
import { DownloadXlsPdfButtonsComponent } from './download-xls-pdf-buttons/download-xls-pdf-buttons.component';
import { DropdownActionsComponent } from './dropdown-actions/dropdown-actions.component';
import { DropdownWithBooleansComponent } from './dropdown-with-booleans/dropdown-with-booleans.component';
import { DropdownWithEnumValuesComponent } from './dropdown-with-enum-values/dropdown-with-enum-values.component';
import { DropdownWithMultiSelectComponent } from './dropdown-with-multiselect/dropdown-with-multiselect.component';
import { DropdownWithSearchComponent } from './dropdown-with-search/dropdown-with-search.component';
import { FormFieldErrorComponent } from './form-field-error/form-field-error.component';
import { GeneratePdfComponent } from './generate-pdf/generate-pdf.component';
import { GenerateXlsComponent } from './generate-xls/generate-xls.component';
import { GradientBorderTitleComponent } from './gradient-border-title/gradient-border-title.component';
import { InputWithFocusComponent } from './input-with-focus/input-with-focus.component';
import { NoItemToShowComponent } from './no-item-to-show/no-item-to-show.component';
import { RolesCheckAllComponent } from './roles-check-all/roles-check-all.component';
import { SendToInvoiceComponent } from './send-to-invoice/send-to-invoice.component';
import { SimpleInputTextComponent } from './simple-input-text/simple-input-text.component';
import { TimerComponent } from './timer/timer.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { UpToDateComponent } from './up-to-date/up-to-date.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { UserAccountFieldsComponent } from './user-account-fields/user-account-fields.component';
import { ProductsStatusComponent } from './products-status/products-status.component';
import { InputFormFieldComponent } from './input-form-field/input-form-field.component';
import { TextAreaFormFieldComponent } from './text-area-form-field/text-area-form-field.component';
import { DatePickerFormFieldComponent } from './date-picker-form-field/date-picker-form-field.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DirectivesModule,
        MaterialModule,
    ],
    declarations: [
        DateRangePickerComponent,
        DropdownActionsComponent,
        GradientBorderTitleComponent,
        InputWithFocusComponent,
        NoItemToShowComponent,
        RolesCheckAllComponent,
        TooltipComponent,
        DropdownWithSearchComponent,
        DropdownWithMultiSelectComponent,
        BadgeComponent,
        DropdownMenuOptionsComponent,
        TimerComponent,
        FormFieldErrorComponent,
        SendToInvoiceComponent,
        UploadFilesComponent,
        DownloadXlsPdfButtonsComponent,
        GeneratePdfComponent,
        GenerateXlsComponent,
        DropdownWithBooleansComponent,
        DropdownWithEnumValuesComponent,
        SimpleInputTextComponent,
        DateIntervalPickerComponent,
        UpToDateComponent,
        UserAccountFieldsComponent,
        DialogFooterButtonsComponent,
        ProductsStatusComponent,
        InputFormFieldComponent,
        TextAreaFormFieldComponent,
        DatePickerFormFieldComponent,
        DateTimePickerComponent,
    ],
    exports: [
        DateRangePickerComponent,
        DropdownActionsComponent,
        GradientBorderTitleComponent,
        InputWithFocusComponent,
        NoItemToShowComponent,
        RolesCheckAllComponent,
        TooltipComponent,
        DropdownWithSearchComponent,
        DropdownWithMultiSelectComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        BadgeComponent,
        DropdownMenuOptionsComponent,
        TimerComponent,
        FormFieldErrorComponent,
        SendToInvoiceComponent,
        UploadFilesComponent,
        DownloadXlsPdfButtonsComponent,
        GeneratePdfComponent,
        GenerateXlsComponent,
        DropdownWithBooleansComponent,
        DropdownWithEnumValuesComponent,
        SimpleInputTextComponent,
        DateIntervalPickerComponent,
        UpToDateComponent,
        UserAccountFieldsComponent,
        DialogFooterButtonsComponent,
        ProductsStatusComponent,
        InputFormFieldComponent,
        TextAreaFormFieldComponent,
        DatePickerFormFieldComponent,
        DateTimePickerComponent,
    ],
})

export class SharedComponentsModule { }