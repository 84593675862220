
<div class="filters">
    <form [formGroup]="form" appFormValidator>
        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <mat-form-field class="w-100" class="customized" floatLabel="always">
                    <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                    <input type="text"
                        matInput
                        id="name"
                        autocomplete="off"
                        formControlName="product.name">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['product.name']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3 d-flex">
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100" class="customized" floatLabel="always">
                        <mat-label for="external_number">{{ 'InventoryNumber' | translate }}:</mat-label>
                        <input type="text"
                            matInput
                            id="external_number"
                            autocomplete="off"
                            formControlName="product.external_number">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['product.external_number']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100" class="customized" floatLabel="always">
                        <mat-label for="internal_number">{{ 'InternalNumber' | translate }}:</mat-label>
                        <input type="text"
                            matInput
                            id="internal_number"
                            autocomplete="off"
                            formControlName="product.internal_number">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['product.internal_number']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 col-md-2">
                <app-dropdown-with-enum-values [enumName]="'inventory_product_statuses'" [label]="'Status'" [firstOptionMessage]="'SelectStatus'"
                    [control]="form.controls.status">
                </app-dropdown-with-enum-values>
            </div>
            <div class="col-12 col-md-3 d-flex">
                <div class="col-12 col-md-6">
                    <app-dropdown-with-booleans [itemsList]="booleanOptions" [control]="form.controls.is_scanned" [label]="'IsScanned'" [firstOptionMessage]="'All'">
                    </app-dropdown-with-booleans>
                </div>
                <div class="col-12 col-md-6">
                    <app-dropdown-with-booleans [itemsList]="booleanOptions" [control]="form.controls.is_imported" [label]="'Imported'" [firstOptionMessage]="'All'">
                    </app-dropdown-with-booleans>
                </div>
            </div>
            <div class="col-12 col-md-5 d-flex justify-content-end h-100 ml-auto">
                <button class="btn btn-primary" (click)="applyFilters()">{{"ApplyFilters" | translate}}</button>
                <button class="btn btn-secondary" [class.btn-active]="activeFilters" (click)="resetFilters()">{{"ResetFilters" | translate}}</button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="inventoryProducts.length; else noProducts">
    <div class="row mt-5 mb-4 justify-content-around align-items-center">
        <div class="col-12 col-md-2 d-flex align-items-center"  *ngIf="!view">
            <mat-checkbox 
            id="selectAllUp" 
            class="d-flex align-items-center"
            [checked]="isChecked()" 
            [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">
            <span class="small">{{(isChecked() ? "DeselectAll" : "SelectAll") | translate}}</span>
            </mat-checkbox> 
        </div>
        <div class="col-12 col-md-4 select-action d-flex align-items-center" *ngIf="!view">
            <span class="pr-3 small selected">{{"Selected" | translate}}: {{selectedInventoryProducts.length}} / {{inventoryProducts.length}}</span>
            <div class="dropdown px-1">
                <button [disabled]="!selectedInventoryProducts.length" class="btn btn-primary small-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{"ChangeStatus" | translate}}
                </button>
                <div class="dropdown-menu stop-refresh" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" (click)="massUpdate('charged')">{{"MarkAllAs" | translate}} {{"Charged" | translate | lowercase}}</a>
                  <a class="dropdown-item" (click)="massUpdate('disposed')">{{"MarkAllAs" | translate}} {{"Disposed" | translate | lowercase}}</a>
                  <a class="dropdown-item" (click)="massUpdate('in_inventory')">{{"MarkAllAs" | translate}} {{"InInventory" | translate | lowercase}}</a>
                  <a class="dropdown-item" (click)="massUpdate('not_processed')">{{"MarkAllAs" | translate}} {{"NotProcessed" | translate | lowercase}}</a>
                </div>
            </div>
            <div class="dropdown px-1" *ngIf="!view">
                <button [disabled]="!selectedInventoryProducts.length" class="btn btn-primary small-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{"ChangeStockManagement" | translate}}
                </button>
                <div class="dropdown-menu stop-refresh" aria-labelledby="dropdownMenuButton">
                    <a *ngFor="let stockManagement of stockManagementsList | async" class="dropdown-item" [class.d-none]="stockManagement.id === stockManagementId" (click)="stockManagementChange(stockManagement)">{{stockManagement.name}}</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-5 px-0  ml-auto">
            <app-products-status [inventoryProducts]="inventoryProducts" [tab]="'-t3'"></app-products-status>
        </div>
    </div>


    <div class="position-relative">
        <div class="pb-4">
            <div class="row subheader-title pb-2 text-uppercase">
                <div class="col-1 small text-center">{{ "#" | translate }}</div>
                <div class="col-4 small text-nowrap"><span class="ml-2">{{ "ProductName" | translate }}</span></div>
                <div class="col-2 small text-center text-nowrap">{{ "InventoryNumber" | translate }} / <br> {{ "InternalNumber" | translate }}</div>
                <div class="col-1 small text-center text-nowrap">{{ "ScannedBy" | translate }}</div>
                <div class="col-1 small text-center text-nowrap">{{ "ScannedAt" | translate }}</div>
                <div class="col-1 small text-center text-nowrap">{{ "Status" | translate }}</div>
                <div class="col-1 small text-center text-nowrap">{{ "Imported" | translate }}</div>
                <div class="col-1 small text-center text-nowrap" *ngIf="!view">{{ "Actions" | translate }}</div>
            </div>
        </div> 
        <div class="loading-dashboard-content w-100 ml-0 position-absolute"
            *ngIf="loading$ | async">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
    
    <div *ngFor="let product of inventoryProducts; index as i">
        <div class="row special-stage" [class.special-stage-even]="i % 2 === 0">
            <div class="col-1 small text-center">{{ i + 1 }}</div>
            <div class="col-4 small text d-flex align-items-baseline">
                <mat-checkbox (click)="$event.stopPropagation()" *ngIf="!view"
                (change)="$event ? toggle(product, $event) : null"
                [checked]="exists(product.id)"></mat-checkbox>
                <div class="px-3">
                    <span>{{ product.product.name }}</span> <br>
                    <div class="small d-flex">
                        <span>{{"Price" | translate}}: <span class="font-weight-bolder text-dark">{{ product.product.price? product.product.price:'-' }}</span></span> 
                        <span class="px-2">|</span>
                        <span>{{"UM" | translate}}: <span class="font-weight-bolder text-dark">{{ product.product.measurement_unit? product.product.measurement_unit: '-' }}</span></span>
                    </div>
                </div>
            </div>
            <div class="col-2 small text-center">
                <span class="text-dark">{{ product.product.external_number}}</span> <br>
                <span class="small">{{ product.product.internal_number}}</span> 
            </div>
            <div class="col-1 small text-center">
                <span class="text-dark">{{ product.scanned_by_user?.first_name}}</span> <br>
                <span class="small">{{ product.scanned_by_user?.last_name}}</span>
            </div>
            <div class="col-1 small text-center">
                <div *ngIf="product.scanned_at;else notScanned" [attr.data-cy]="'date-' + product.product.external_number">
                    <span class="text-dark">{{ (utcConvert(product.scanned_at) | date : 'dd.MM.YYYY') || '-'}}</span> <br>
                    <span class="small font-weight-bold">{{ (utcConvert(product.scanned_at) | date : 'HH:mm') || '-'}}</span>
                </div>
                <ng-template #notScanned>-</ng-template>
            </div>
            <div class="col-1 small text-center"><span [attr.data-cy]="'badge-' + product.product.external_number" class="badge badge-{{product.status}}">{{ product.status | translate}}</span></div>
            <div class="col-1 small text-center">
                <ng-container *ngIf="product.imported_at; else notImportedProduct">
                    <span [attr.data-cy]="'confirmed-' + product.product.external_number" class="material-icons text-success">task_alt</span>
                </ng-container>
                <ng-template #notImportedProduct>
                    <span [attr.data-cy]="'unconfirmed-' + product.product.external_number" class="material-icons text-danger">error_outline</span>
                </ng-template>
            </div>
            <div class="col-1 small text-center" *ngIf="!view">
                <div class="dropdown">
                    <button href="#" class="btn btn-drop-custom" data-toggle="dropdown" aria-expanded="true" [attr.data-cy]="'actions-' + product.product.external_number">
                        <i class="material-icons">arrow_drop_down</i>
                    </button>
                    <ul class="dropdown-menu stop-refresh">
                        <li><a [attr.data-cy]="'not-processed-action-'+product.product.external_number" (click)="updateProduct(product, 'not_processed')">{{ "not_processed" | translate }}</a></li>
                        <li><a [attr.data-cy]="'disposed-action-'+product.product.external_number" (click)="updateProduct(product, 'disposed')">{{ "disposed" | translate }}</a></li>
                        <li><a [attr.data-cy]="'charged-action-'+product.product.external_number" (click)="updateProduct(product, 'charged')">{{ "charged" | translate }}</a></li>
                        <li><a [attr.data-cy]="'in-inventory-action-'+product.product.external_number" (click)="updateProduct(product, 'in_inventory')">{{ "in_inventory" | translate }}</a></li>
                    </ul>
                </div>
            </div>

        </div>
    </div>

    <div class="spacer-2"></div>

    <div class="row mb-5" *ngIf="!view">
        <div class="col-12 col-md-2 d-flex align-items-center">
            <mat-checkbox 
            id="selectAllUp" 
            class="d-flex align-items-center"
            [checked]="isChecked()" 
            [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">
            <span class="small">{{(isChecked() ? "DeselectAll" : "SelectAll") | translate}}</span>
            </mat-checkbox> 
        </div>
        <div class="col-12 col-md-4 select-action d-flex align-items-center">
            <span class="pr-3 small selected">{{"Selected" | translate}}: {{selectedInventoryProducts.length}} / {{inventoryProducts.length}}</span>
            <div class="dropdown px-1">
                <button [disabled]="!selectedInventoryProducts.length" class="btn btn-primary small-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  {{"ChangeStatus" | translate}}
                </button>
                <div class="dropdown-menu stop-refresh" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" (click)="massUpdate('charged')">{{"MarkAllAs" | translate}} {{"Charged" | translate | lowercase}}</a>
                  <a class="dropdown-item" (click)="massUpdate('disposed')">{{"MarkAllAs" | translate}} {{"Disposed" | translate | lowercase}}</a>
                  <a class="dropdown-item" (click)="massUpdate('in_inventory')">{{"MarkAllAs" | translate}} {{"InInventory" | translate | lowercase}}</a>
                  <a class="dropdown-item" (click)="massUpdate('not_processed')">{{"MarkAllAs" | translate}} {{"NotProcessed" | translate | lowercase}}</a>
                </div>
            </div>
            <div class="dropdown px-1" >
                <button [disabled]="!selectedInventoryProducts.length" class="btn btn-primary small-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{"ChangeStockManagement" | translate}}
                </button>
                <div class="dropdown-menu stop-refresh" aria-labelledby="dropdownMenuButton">
                <a *ngFor="let stockManagement of stockManagementsList | async" class="dropdown-item" [class.d-none]="stockManagement.id === stockManagementId" (click)="stockManagementChange(stockManagement)">{{stockManagement.name}}</a>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noProducts>
    <app-no-item-to-show [message]="'NoInventoryItems'"></app-no-item-to-show>
</ng-template>