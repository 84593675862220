export class InventoryModel {
    id?: number;
    start_date: string;
    end_date: string | null;

    manager_name: string;
    manager_position: string;
    company_manager_name: string;
    company_manager_position: string;
    company_manager_investment_decision_number: string;
    company_manager_investment_decision_date: string;
    lists_author_name?: string;
    lists_author_position?: string;
    claims_and_obligations_approval_responsible_name?: string;
    claims_and_obligations_approval_responsible_position?: string;
    approval_report_number?: string;
    approval_report_date?: string;
    approval_report_author_name?: string;
    approval_report_author_position?: string;
    approval_decision_number?: string;
    approval_decision_date?: string;
    trainer_name?: string;
    trainer_position?: string;
    training_date_and_hour?: string;
    training_location?: string;
    training_notification_date?: string;
    training_report_date?: string;
    training_procedure_code?: string;
    training_provided_protective_equipment?: string;
    training_provided_tools?: string;
    declaration_number?: string;
    declaration_date?: string;
    declaration_storage_place?: string;
    declaration_third_parties_values?: string;
    declaration_acknowledged_stock_differences?: string;
    declaration_acknowledged_undocumented_stock_items?: string;
    declaration_documented_stock_items?: string;
    declaration_unprocessed_documents_for_the_stock_items?: string;
    declaration_cash_value?: string;
    declaration_stock_entry_documents?: string;
    declaration_stock_exit_documents?: string;
    declaration_last_report_date?: string;
    declaration_last_report_period_start_date?: string;
    declaration_last_report_period_end_date?: string;
    declaration_other_information?: string;
}
export class ProductsUploadFormat {
    id: number;
    file: any;
    overwrite_stock_management: boolean;
}

export class InventoryProductModel {
    id?: number;
    filters?: string;
    scanned_at?: string;
    inventory_product?: {
        id: number;
        inventory_id: number;
    }
    status?: string;
}



