<form [formGroup]="form"
      appFormValidator>
    <div class="form-row mt-4">
        <div class="col-md-3">
            <mat-form-field class="w-100" class="customized" floatLabel="always">
                <mat-label for="lname">{{ 'LastName' | translate }}:</mat-label>
                <input type="text"
                       matInput
                       id="lname"
                       autocomplete="off"
                       formControlName="last_name">
                <mat-error>
                    <app-form-field-error [control]="form.controls['last_name']"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="w-100" class="customized" floatLabel="always">
                <mat-label for="fname">{{ 'FirstName' | translate }}:</mat-label>
                <input type="text"
                       matInput
                       id="fname"
                       autocomplete="off"
                       formControlName="first_name">
                <mat-error>
                    <app-form-field-error [control]="form.controls['first_name']"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="w-100" class="customized" floatLabel="always">
                <mat-label for="mobile">{{ 'Mobil' | translate }}</mat-label>
                <input type="text"
                       matInput
                       id="mobile"
                       autocomplete="off"
                       formControlName="mobile">
                <mat-error>
                    <app-form-field-error [control]="form.controls['mobile']"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="w-100" class="customized" floatLabel="always">
                <mat-label for="email">{{ 'Email' | translate }}</mat-label>
                <input type="text"
                       matInput
                       id="email"
                       autocomplete="off"
                       formControlName="email">
                <mat-error>
                    <app-form-field-error [control]="form.controls['email']"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="form-row">
        <div class="col-md-{{!hideRoleFields ? 4 : 3 }}">
            <mat-form-field class="customized" floatLabel="'always'">
                <mat-label for="username">{{ 'Username' | translate }}</mat-label>
                <input type="text"
                       matInput
                       id="username"
                       autocomplete="off"
                       formControlName="username">
                <mat-error>
                    <app-form-field-error [control]="form.controls['username']"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-{{!hideRoleFields ? 4 : 3 }}">
            <ng-container>
                <mat-form-field class="mb-1 customized" floatLabel="'always'">
                    <mat-label for="inputState">{{ 'Status' | translate }}:</mat-label>
                    <mat-select matSelect
                                id="inputState"
                                formControlName="status">
                        <mat-option *ngFor="let status of userStatuses"
                                    [value]="status.value">
                            {{ status.title | titlecase | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        <app-form-field-error [control]="form.controls['status']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="col-md-{{!hideRoleFields ? 6 : 3 }}">
            <mat-form-field class="customized" floatLabel="'always'">
                <mat-label for="password">{{ 'Password' | translate }}</mat-label>
                <input type="password"
                       matInput
                       id="password"
                       autocomplete="off"
                       formControlName="password">
                <mat-error>
                    <app-form-field-error [control]="form.controls['password']"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-{{!hideRoleFields ? 6 : 3 }}">
            <mat-form-field class="customized" floatLabel="'always'">
                <mat-label for="password_confirmation">{{ 'ConfirmPassword' | translate }}</mat-label>
                <input type="password"
                       matInput
                       id="password_confirmation"
                       autocomplete="off"
                       formControlName="password_confirmation">
                <mat-error>
                    <app-form-field-error [control]="form.controls['password_confirmation']"></app-form-field-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</form>