<mat-form-field class="w-100" class="customized" floatLabel="always">
    <mat-label [attr.for]="id">{{ label | translate }}:</mat-label>
    <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        [attr.cdkAutosizeMaxRows]="maxRows"
        [id]="id"
        autocomplete="off"
        [formControl]="control">
    </textarea>
    <mat-error>
        <app-form-field-error [control]="control"></app-form-field-error>
    </mat-error>
</mat-form-field>