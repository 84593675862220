import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-input-form-field',
    templateUrl: './input-form-field.component.html',
    styleUrls: ['./input-form-field.component.css']
})
export class InputFormFieldComponent {
    @Input() control: FormControl;
    @Input() label: string;
    @Input() id: string;
    @Input() type: string;
}
