<div class="row dialog-top-header d-flex" mat-dialog-title aria-hidden="false" aria-modal="true">
    <div class="col-10 dialog-pre-title">
        <span class="small">{{"Inventories" | translate}}</span>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn"
                (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="col-12 pb-3">
        <h1 class="mb-0 dialog-title"
            *ngIf="isEdit">
            <span>{{'EditInventory' | translate}} {{'ForTheStockManagement' | translate | lowercase}}</span>:
            <span *ngIf="selectedInventory$ | async as inventory; else namePlaceholder">
                {{ inventory?.stock_management?.name }}
            </span>
            <ng-template #namePlaceholder>-</ng-template>
        </h1>

        <h1 class="mb-0 dialog-title"
            *ngIf="!isEdit">
            {{'AddInventory' | translate}}
        </h1>
    </div>
    <div class="card-header card-header-tabs card-header-secondary">
        <div class="nav-tabs-navigation">
            <ul role="tablist"
                class="nav nav-pills nav-pills-primary">
                <li class="nav-item">
                    <a data-toggle="tab"
                       href="#inventory"
                       role="tablist"
                       data-cy="inventory-tab"
                       class="nav-link active">{{ "Inventory" | translate }}
                    </a>
                </li>
                <li class="nav-item">
                    <a data-toggle="tab"
                       href="#docs"
                       role="tablist"
                       data-cy="docs-tab"
                       class="nav-link">{{ "Documents" | translate }}
                    </a>
                </li>
                <li class="nav-item"
                    *ngIf="isEdit && !closedInventory">
                    <a data-toggle="tab"
                       href="#importFiles"
                       role="tablist"
                       data-cy="import-tab"
                       class="nav-link">{{ "ImportFiles" | translate }}
                    </a>
                </li>
                <li class="nav-item"
                    *ngIf="isEdit">
                    <a data-toggle="tab"
                       href="#inventoryProducts"
                       role="tablist"
                       data-cy="inventory-products-tab"
                       class="nav-link">{{ "InventoryProducts" | translate }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<mat-dialog-content>
    <div class="position-relative max-height-65">
        <div class="full-loading-overlay w-100"
             *ngIf="(loading$ | async)">
            <span class="spinner"></span>
        </div>
        <div class="tab-content tab-space h-100 pb-0">
            <div id="inventory" class="tab-pane active pt-3">

                 <form [formGroup]="form" appFormValidator>
                    <div class="form-row mt-4">
                        <div class="col-8 d-flex flex-wrap pr-5">
                            <div class="col-12 d-flex px-0" *ngIf="!closedInventory; else isClosed">
                                <div class="col-4 pl-0">
                                    <app-dropdown-with-search 
                                    [data_cy]="'stock-management'"
                                    [control]="form.controls.stock_management_id" 
                                    [itemsList]="stockManagementsList | async" 
                                    [label]="'StockManagement'"
                                    [cypressSelector]="'stock-management'" 
                                    firstOptionMessage="SelectStockManagement">
                                    </app-dropdown-with-search>
                                </div>
                                <div class="col-4" [class.d-none]="closedInventory">
                                    <app-date-picker-form-field [id]="'start_date'" [control]="form.controls['start_date']" [label]="'StartDate'"></app-date-picker-form-field>
                                </div>
                                <div class="col-4" [class.d-none]="closedInventory">
                                    <app-date-picker-form-field [id]="'estimated_finish_date'" [control]="form.controls['estimated_finish_date']" [label]="'EstimatedFinishDate'"></app-date-picker-form-field>
                                </div>
                            </div>

                            <ng-template #isClosed>
                                <div class="inventory-date col-12 flex-row align-tems-center card mt-0" *ngIf="selectedInventory$ | async as inventoryStatus">
                                    <div class="body col-6 px-5">
                                        <div class="row pl-1">
                                            <mat-label class="card-section-title mb-2">{{ "StartDate" | translate }}:</mat-label>
                                        </div>
                                        <div class="row">
                                            <mat-icon class="begin-date">today</mat-icon>
                                            <span class="pt-0">{{ inventoryStatus.start_date | date : "dd.MM.yyyy" }}</span>
                                        </div>
                                    </div>
                                    <div class="body col-6 px-5">
                                        <div class="row pl-1">
                                            <mat-label class="card-section-title mb-2">{{ "EndDate" | translate }}:</mat-label>
                                        </div>
                                        <div class="row">
                                            <mat-icon class="end-date">insert_invitation</mat-icon>
                                            <span class="pt-0">{{ inventoryStatus.end_date | date : "dd.MM.yyyy" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <div class="col-12 col-md-6 d-flex pl-0" *ngIf="isEdit">
                                <div class="dropdown"  [appRestrictPermissions]="PermissionsEnum.QR_CODES_READ">
                                    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      {{"ExportQR" | translate}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                      <a class="dropdown-item" (click)="generateQR()">{{"ForAllProductsFromThisInventory" | translate}}</a>
                                      <a class="dropdown-item" (click)="generateQR(true)">{{"ForNewProductsAddedInThisInventory" | translate}}</a>
                                    </div>
                                </div>
                                <button class="btn btn-danger" data-cy="close-inventory-btn" (click)="closeInventory()" *ngIf="!closedInventory">{{'CloseInventory' | translate}}</button>
                            </div>

                        </div>

                        <div class="col-12 col-md-4 ml-auto px-0">
                            <app-products-status>
                                [inventoryProducts]="importedProducts | async">
                            </app-products-status>
                        </div>
                    </div>
                    <app-inventory-form-fields [isDisabled]="closedInventory" (updateManagerForm)="updateManagerForm($event)" [data]="selectedInventory$ | async"></app-inventory-form-fields>
                </form>
            </div>
            <div id="docs" class="tab-pane pt-3"  >
                <app-docs-tab [isDisabled]="closedInventory" (updateDocsForm)="updateDocsForm($event)" [data]="selectedInventory$ | async" ></app-docs-tab>
            </div>
            <div id="importFiles" class="tab-pane pt-3" *ngIf="isEdit">
                <div [class.d-none]="!(uploadSuccessfully$ | async)">
                    <app-products-upload-successfull></app-products-upload-successfull>
                </div>
                <div [class.d-none]="uploadSuccessfully$ | async" *rerender="uploadSuccessfully$ | async">
                    <app-upload-files (downloadTemplate)="getImportFormat()" (onUpload)="uploadFile($event)" [fileType]="'.csv'" (clearErrors)="clearErrors()"></app-upload-files>
                </div>
            </div>
            <div id="inventoryProducts" class="tab-pane pt-3" *ngIf="isEdit">
                <app-inventory-products [id]="inventoryId" [view]="closedInventory" (closeModal)="dialogRef.close()" [stockManagementsList]="stockManagementsList" [stockManagementId]="data?.stock_management_id"></app-inventory-products>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="mb-3">
    <mat-dialog-actions>
        <app-dialog-footer-buttons class="w-100"
            (close)="dialogRef.close()"
            (saveAndClose)="saveAndClose()"
            (save)="onSubmit()"
            [view]="closedInventory">
        </app-dialog-footer-buttons>
    </mat-dialog-actions>
</div>