<mat-form-field class="customized"  floatLabel="always">
    <mat-label [attr.for]="dynamicRef">{{ label | translate }}</mat-label>
    <input matInput [ngxMatDatetimePicker]="dynamicRef" [formControl]="value"
       [min]="minDate" [max]="maxDate" (click)="dynamicRef.open()">
    <mat-datepicker-toggle matSuffix [for]="dynamicRef"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #dynamicRef [showSpinners]="true" [enableMeridian]="false" >
    </ngx-mat-datetime-picker>
    <mat-error>
        <app-form-field-error [control]="value"></app-form-field-error>
    </mat-error>
 </mat-form-field>