import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccountsModule } from './modules/accounts/accounts.module';
import { RolesModule } from './modules/roles/roles.module';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MatCommonModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { TablesModule } from './shared/components/tables/tables.module';
import { DirectivesModule } from './shared/directives/directives.module';
import { MaterialModule } from './shared/modules/material.module';
import { PipesModule } from './shared/pipes/pipes.module';

import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './shared/layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';

import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppEffects } from 'src/app/store/app.effects';
import { AppFacade } from 'src/app/store/app.facade';
import { appReducer } from 'src/app/store/app.reducers';
import { BrandInvoiceProfileModule } from './modules/brand-invoice-profile/brand-invoice-profile.module';
import { BrandModule } from './modules/brand-profile/brand.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { FinancialModule } from './modules/financial/financial.module';
import { ReportsModule } from './modules/reports/reports.module';
import { SettingsModule } from './modules/settings/settings.module';
import { HttpRequestInterceptor } from './shared/_helpers/http-request-interceptor';
import { FooterModule } from './shared/components/footer/footer.module';
import { NotAllowedComponent } from './shared/components/not-allowed/not-allowed.component';
import { SweetAlertComponent } from './shared/components/sweetalert/sweetalert.component';
import { BuildDetailsService } from './shared/services/build-details.service';
import { LangService } from './shared/services/lang.service';
import { PaginatorI18n } from './shared/components/tables/regular-table/paginatorI18n';
import { StockManagementsModule } from './modules/stock-managements/stock-managements.module';
import { InventoryModule } from './modules/inventories/inventories.module';
import { ProductsModule } from './modules/products/products.module';
import { QRScanModule } from './modules/qr-scan/qr-scan.module';
import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter, NgxMatDateFormats, NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CustomDateAdapterService } from './shared/services/custom-date-adapter.service';
registerLocaleData(localeRo)


@NgModule({
    imports: [
        MatCommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true,
        }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        MaterialModule,
        FooterModule,
        TablesModule,
        DirectivesModule,
        PipesModule,
        DragDropModule,
        SharedComponentsModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatNativeDateModule,
        DashboardModule,
        AccountsModule,
        RolesModule,
        BrandInvoiceProfileModule,
        SettingsModule,
        BrandModule,
        ReportsModule,
        FinancialModule,
        StockManagementsModule,
        InventoryModule,
        ProductsModule,
        QRScanModule,
        StoreModule.forRoot({ app: appReducer } as ActionReducerMap<any, any>),
        EffectsModule.forRoot([AppEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),
    ],
    declarations: [
        AppComponent,
        AuthLayoutComponent,
        SweetAlertComponent,
        NotAllowedComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        { provide: APP_INITIALIZER, useFactory: fetchBuildDetails, deps: [BuildDetailsService], multi: true },
        { provide: LOCALE_ID, useValue: 'ro-RO' },
        { provide: MAT_DATE_LOCALE, useValue: 'ro-RO' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        }, LangService,
        AppFacade
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

export function fetchBuildDetails(buildDetailsService: BuildDetailsService) {
    return () => buildDetailsService.getBuildDetails();
}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

