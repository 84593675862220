<div class="spacer-4"></div>
<div class="filters container">
    <form [formGroup]="form" appFormValidator>
        <div class="form-row mt-4">
            <div class="col-12 col-md-3">
                <app-dropdown-with-search 
                [control]="form.controls.inventory_id" 
                [itemsList]="inventoriesList$ | async" 
                [label]="'Inventory'"
                [cypressSelector]="'active-inventories'"
                firstOptionMessage="SelectInventory">
                </app-dropdown-with-search>
            </div>
            <div class="col-12 col-md-3" [class.disabled-input]="!form.controls.inventory_id.value">
                <mat-form-field class="w-100" class="customized" floatLabel="always">
                    <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                    <input type="text"
                        matInput
                        id="name"
                        autocomplete="off"
                        [readonly]="!form.controls.inventory_id.value"
                        formControlName="product.name">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['product.name']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3" [class.disabled-input]="!form.controls.inventory_id.value">
                <mat-form-field class="w-100" class="customized" floatLabel="always"  >
                    <mat-label for="external_number">{{ 'InventoryNumber' | translate }}:</mat-label>
                    <input type="text"
                        matInput
                        id="external_number"
                        autocomplete="off"
                        [readonly]="!form.controls.inventory_id.value"
                        formControlName="product.external_number">
                    <mat-error>
                        <app-form-field-error [control]="form.controls['product.external_number']"></app-form-field-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-3" [class.disabled-input]="!form.controls.inventory_id.value">
                <app-dropdown-with-booleans [itemsList]="booleanOptions" [control]="form.controls.is_scanned" [label]="'IsScanned'" [firstOptionMessage]="'All'" [attr.is_disabled]="!form.controls.inventory_id.value">
                </app-dropdown-with-booleans>
            </div>
            <div class="col-12 col-md-3" [class.disabled-input]="!form.controls.inventory_id.value">
                <app-dropdown-with-enum-values [enumName]="'inventory_product_statuses'" [label]="'Status'" [firstOptionMessage]="'SelectStatus'" [attr.is_disabled]="!form.controls.inventory_id.value"
                    [control]="form.controls.status">
                </app-dropdown-with-enum-values>
            </div>
            <div class="col-md-5 d-flex align-items-center {{ isMobile ? 'justify-content-around' : 'justify-content-end' }} ml-auto">
                <button class="btn btn-primary {{ isMobile ? 'btn-sm px-2':''}}" 
                (click)="applyFilters()"  [class.disabled-input]="!form.controls.inventory_id.value">
                <span class="material-icons mr-1">search</span>
                {{"ApplyFilters" | translate}}
                </button>
                <button class="btn btn-secondary {{ isMobile ? 'btn-sm px-2':''}}" 
                [class.btn-active]="activeFilters" 
                (click)="resetFilters()">
                <span class="material-icons mr-1">refresh</span>
                {{"ResetFilters" | translate}}
                </button>
            </div>
        </div>
    </form>
</div>

<div class="products-list container" *ngIf="inventoryProducts?.length; else noProducts">
    <div class="d-flex mt-3 product-info-card">
        <div class="col-12 d-flex flex-column align-items-center pt-4">
            <div class="d-flex pb-2">
                <div class="pb-1">{{ "IsScanned" | translate }}:</div>
                <div class="border-bottom-0 pt-0 pl-2">
                    <p class="text-right font-weight-bold medium mb-0 text-accent">{{ totalScanned }} / {{ inventoryProducts.length }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="loading-dashboard-content w-100 ml-0 position-absolute"
        *ngIf="loading$ | async">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngFor="let product of inventoryProducts; index as i" class="py-2">
        <div class="row special-stage" [class.special-stage-even]="i % 2 === 0">
            <div class="col-12 d-flex justify-content-between">
                <div class="small text d-flex align-items-baseline">
                <span class="pr-1">{{ i + 1 }}.&nbsp; </span>
                <div *ngIf="product.product.external_number; else internalNumber"> <span> {{"NrInv" | translate}}</span>:&nbsp;<span class="font-weight-bold inventory-no">{{ product.product.external_number}}</span></div></div> 
                <ng-template #internalNumber>
                    <span> {{"InternalNumber" | translate}}</span>:&nbsp;<span class="font-weight-bold inventory-no">{{ product.product.internal_number}}</span>
                </ng-template>
                <div class="small"><span class="badge badge-{{product.status}}">{{ product.status | translate}}</span></div>
            </div>
            <div class="col-12 pr-0 pt-2">
                <div class="col-12">
                    <span class="small">{{ product.product.name }} </span>
                </div>
            </div>
            <div class="col-12 d-flex pr-0 pt-2">
                <div class="col-5 small">
                    {{"ScannedAt" | translate}}:
                </div>
                <div class="col-7 small text-right">
                    <div *ngIf="product.scanned_at;else notScanned" class="small">
                        <span>{{ (utcConvert(product.scanned_at) | date : 'dd.MM.YYYY') || '-'}}</span> &nbsp;<span class="font-weight-bold">{{ (utcConvert(product.scanned_at) | date : 'HH:mm') || '-'}}</span>
                    </div>
                    <ng-template #notScanned><span class="text-danger">{{"NotScanned" | translate}}</span></ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #noProducts>
    <app-no-item-to-show [message]="'NoItemsToShow'"></app-no-item-to-show>
</ng-template>