<div class="row dialog-top-header d-flex" mat-dialog-title>
    <div class="col-10 dialog-pre-title">
        <small>{{"Product" | translate}}</small>
    </div>
    <div class="col-2 text-right">
        <button class="btn btn-secondary-custom small-btn"
                (click)="dialogRef.close()">
            <span class="material-icons">close</span>
        </button>
    </div>
    <div class="col-12">
        <h1 class="mb-0 dialog-title">
            {{'EditProduct' | translate}}:
            <span *ngIf="selectedProduct$ | async as product; else namePlaceholder">
                {{ product?.name }}
            </span>
            <ng-template #namePlaceholder>-</ng-template>
        </h1>
    </div>
</div>
    
<mat-dialog-content>
    <div class="position-relative">
        <div class="full-loading-overlay w-100"
             *ngIf="(loading$ | async)">
            <span class="spinner"></span>
        </div>
    </div>
    <div class="spacer-2"></div>
    <form [formGroup]="form" appFormValidator>
            <div class="form-row mt-4">
                <div class="col-12 col-md-3">
                    <mat-form-field class="w-100" class="customized" floatLabel="always">
                        <mat-label for="name">{{ 'Name' | translate }}:</mat-label>
                        <input type="text"
                            matInput
                            id="name"
                            autocomplete="off"
                            formControlName="name">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['name']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <app-dropdown-with-search [control]="form.controls.stock_management_id" [itemsList]="stockManagementList$ | async" [label]="'StockManagement'"
                        [cypressSelector]="'stock-management'" firstOptionMessage="SelectStockManagement">
                    </app-dropdown-with-search>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field class="w-100" class="customized" floatLabel="always">
                        <mat-label for="external_number">{{ 'InventoryNumber' | translate }}:</mat-label>
                        <input type="text"
                            matInput
                            id="external_number"
                            autocomplete="off"
                            formControlName="external_number">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['external_number']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field class="w-100" class="customized" floatLabel="always">
                        <mat-label for="measurement_unit">{{ 'MeasurementUnit' | translate }}:</mat-label>
                        <input type="text"
                            matInput
                            id="measurement_unit"
                            autocomplete="off"
                            formControlName="measurement_unit">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['measurement_unit']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field class="w-100" class="customized" floatLabel="always">
                        <mat-label for="price">{{ 'Price' | translate }}:</mat-label>
                        <input type="text"
                            matInput
                            id="price"
                            autocomplete="off"
                            formControlName="price">
                        <mat-error>
                            <app-form-field-error [control]="form.controls['price']"></app-form-field-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    
</mat-dialog-content>
<div class="mb-3">
    <mat-dialog-actions> 
            <app-dialog-footer-buttons class="w-100"
                               (close)="dialogRef.close()"
                               (saveAndClose)="saveAndClose()"
                               (save)="onSubmit()">
            </app-dialog-footer-buttons>
    </mat-dialog-actions>
</div>