<form [formGroup]="form" appFormValidator>
    <div class="d-flex align-items-baseline">
        <div class="form-row mt-4 col-md-3">
            <div class="col-12 mb-3">
                <app-gradient-border-title>{{ "InventoryManager" | translate }}</app-gradient-border-title>
            </div>
            <div class="col-md-12">
                <app-input-form-field [id]="'manager_name'" [control]="form.controls['manager_name']" [label]="'InventoryManagerNameSurname'"></app-input-form-field>
            </div>
            <div class="col-md-12">
                <app-input-form-field [id]="'manager_position'" [control]="form.controls['manager_position']" [label]="'InventoryManagerPosition'"></app-input-form-field>
            </div>
        </div>
        <div class="form-row mt-4 col-md-3">
            <div class="col-12 mb-3">
                <app-gradient-border-title>{{ "InventoryLists" | translate }}</app-gradient-border-title>
            </div>
            <div class="col-md-12">
                <app-input-form-field [id]="'lists_author_name'" [control]="form.controls['lists_author_name']" [label]="'InventoryListAuthorName'"></app-input-form-field>
            </div>
            <div class="col-md-12">
                <app-input-form-field [id]="'lists_author_position'" [control]="form.controls['lists_author_position']" [label]="'InventoryListsAuthorPosition'"></app-input-form-field>
            </div>
        </div>
        <div class="form-row mb-4 col-md-3">
            <div class="col-12 mb-3">
                <app-gradient-border-title>{{ "InventoryClaimsObligations" | translate }}</app-gradient-border-title>
            </div>

            <div class="col-md-12">
                <app-input-form-field [id]="'claims_and_obligations_approval_responsible_name'" [control]="form.controls['claims_and_obligations_approval_responsible_name']" [label]="'inventoryClaimsAndObligationsApprovalResponsibleName'"></app-input-form-field>
            </div>
            <div class="col-md-12">
                <app-input-form-field [id]="'claims_and_obligations_approval_responsible_position'" [control]="form.controls['claims_and_obligations_approval_responsible_position']" [label]="'InventoryClaimsAndObligationsApprovalResponsiblePosition'"></app-input-form-field>
            </div>
        </div>
        <div class="form-row mt-4 col-md-3">
            <div class="col-12 mb-3">
                <app-gradient-border-title>{{ "InventoryCompanyManager" | translate }}</app-gradient-border-title>
            </div>
            <div class="col-md-12">
                <app-input-form-field [id]="'company_manager_name'" [control]="form.controls['company_manager_name']" [label]="'InventoryCompanyManagerName'"></app-input-form-field>
            </div>
            <div class="col-md-12">
                <app-input-form-field [id]="'company_manager_position'" [control]="form.controls['company_manager_position']" [label]="'InventoryCompanyManagerPosition'"></app-input-form-field>
            </div>
            <div class="col-md-6">
                <app-input-form-field [id]="'company_manager_investment_decision_number'" [control]="form.controls['company_manager_investment_decision_number']" [label]="'InventoryCompanyManagerInvestmentDecisionNo'" ></app-input-form-field>
            </div>
            <div class="col-md-6">
                <app-date-picker-form-field [id]="'company_manager_investment_decision_date'" [control]="form.controls['company_manager_investment_decision_date']" [label]="'InventoryCompanyManagerInvestmentDecisionDate'"></app-date-picker-form-field>
            </div>
        </div>
    </div>
</form>